<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Receipts</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="receipts"
                    :loading="loading"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-text-field
                            class="my-2"
                            label="Search by eir_no..."
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>   
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                                    v-model="dateRangeText"
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>
                        <!--Data Picker finish-->
                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>                                             
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    @click="downloadReceipt(item)"
                                    v-on="on"
                                    color="green"
                                >mdi-download</v-icon>
                            </template>
                            <span>Download Receipt</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>                
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            headers: [
            {
                text: "id",
                value: "id",
                sortable: false,
            },
            {
                text: "eir_no",
                value: "eir_no",
                sortable: false,
            },
            {
                text: "date",
                value: "date",
                sortable: false,
            },
            {
                text: "shipping_line",
                value: "shipping_line",
                sortable: false,
            },
            {
                text: "container#",
                value: "container_number",
                sortable: false,
            },
            {
                text: "size",
                value: "container_size",
                sortable: false,
            },
            {
                text: "type",
                value: "container_type",
                sortable: false,
            },
            {
                text: "class",
                value: "container_class",
                sortable: false,
            },
            {
                text: "msg_date",
                value: "manufacturing_date",
                sortable: false,
            },
            {
                text: "gross_weight",
                value: "mass_gross_weight",
                sortable: false,
            },
            {
                text: "tare_weight",
                value: "tare_weight",
                sortable: false,
            },
            {
                text: "cedex_code",
                value: "cedex_code",
                sortable: false,
            },
            {
                text: "broker",
                value: "broker_name",
                sortable: false,
            },
            {
                text: "plate#",
                value: "plate_number",
                sortable: false,
            },
            {
                text: "destination",
                value: "destination_type",
                sortable: false,
            },
            {
                text: "container_location",
                value: "container_location",
                sortable: false,
            },
            {
                text: "repair_classification",
                value: "repair_classification",
                sortable: false,
            },
            {
                text: "surveyor",
                value: "surveyor_name",
                sortable: false,
            },
            {
                text: "created_at",
                value: "created_at",
                sortable: false,
            },
            {
                text: "actions",
                value: "actions",
                sortable: false,
            },

            ],
            receipts: [],
            overlay: false,
            loading: false,
            search: null,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ],
            menu: false,
        }
    },
    mounted() {
        if( this.$store.state.user.role == 'mrstaff' || this.$store.state.user.role == 'user'|| this.$store.state.user.role == 'qronly' || this.$store.state.user.role == 'hrassistant' ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }        
        this.loadReceipts();

        if( this.$route.params.eir_no ) {
            this.search = this.$route.params.eir_no;
        }        
    },
    methods: {
        loadReceipts: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_receipts',
                {
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                    dates: this.dates,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.receipts = response.data.data;
                        this.totalNumberOfRecords = response.data.total;
                        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                    } else {
                        this.$store.commit('logOut');
                    }

                }).catch(error => {
                    console.log(error);
                    this.$store.commit('logOut');
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        searchByKeyword: function() {
            this.page = 1;
            this.loadReceipts();
        },        
        changePage: function() {
            this.loadReceipts();
        },        
        downloadReceipt: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                "/api/download_receipt",
                {
                    item: item,
                },
                {
                    responseType: "blob",
                }
                )
                .then((response) => {
                    this.downloading = false;
                    this.overlay = false;
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/jpeg" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    this.downloading = false;
                    this.overlay = false;
                    console.dir(error);
                });            
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },    
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },    
}
</script>