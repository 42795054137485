<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <!-- <v-btn @click="test">test</v-btn> -->
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Booking</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
            >
            <template v-slot:top>
              <v-btn
                dark
                color="red"
                class="lighten-2 my-2"
                block
                max-width="100%"
                @click="openEncodeContainerInformationDialog"
              >
                <v-icon>mdi-playlist-edit</v-icon> Encode Container Info.
              </v-btn>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search Container#..."
                placeholder="Search Container#..."
                outlined
                dense
                @keyup="searchByKeyword()"
              ></v-text-field>      
                <!--Data Picker start-->
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                            v-model="dateRangeText"
                            label="Date Range"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click="dates = []"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        range
                    ></v-date-picker>
                </v-menu>
                <!--Data Picker finish-->
                <v-btn
                  block
                  dark
                  color="blue"
                  max-width="100%"
                  class="my-2"
                  @click="openBookingScoreboardDialog()"
                >
                  <v-icon>mdi-list-box</v-icon>
                  booking scoreboard
                </v-btn>                    
                <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
                v-model="page"
                :length="pageCount"
                total-visible="10"
                dark
                @input="changePage()"
            ></v-pagination>
        </div>
          </v-card-text>
        </v-card>
        <!--encodeContainerInformationDialog start-->
        <v-dialog v-model="bookingScoreboardDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                  <v-toolbar flat dark class="indigo lighten-3">
                      <v-toolbar-title>
                        Booking Scoreboard - {{ dates[0] + ' ~ ' + dates[1] }} -
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        dark
                        @click="bookingScoreboardDialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-btn
                      block
                      dark
                      color="green"
                      max-width="100%"
                      class="my-2"
                      @click="downloadBookingScorboardExcel()"
                    >
                      <v-icon>mdi-microsoft-excel</v-icon>
                      Download Excel
                    </v-btn>                  
                    
                    <v-data-table
                      :headers="bookingScoreboardHeaders"
                      :items="bookingScoreboardData"
                    ></v-data-table>

                    
                  </v-card-text>
                </v-container>
              </v-card>
            </v-dialog>
          <!-- bookingScoreboardDialog end -->
        <!--encodeContainerInformationDialog start-->
        <v-dialog v-model="encodeContainerInformationDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                          Encode Container Information
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="encodeContainerInformationDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-card-text>
                            <!-- <v-text-field
                                outlined
                                dense
                                label="UserEmailAddress"
                                placeholder="UserEmailAddress"
                                v-model="userEmailAddress"
                                :rules="rules"
                            ></v-text-field> -->
                            <v-text-field
                                outlined
                                dense
                                label="ContainerNumber"
                                placeholder="ContainerNumber"
                                v-model="containerNumber"
                                :rules="rules"
                                @keyup="upperCase()"
                            ></v-text-field>
                            <v-select
                                outlined
                                dense
                                label="CompanyName"
                                placeholder="CompanyName"
                                v-model="companyId"
                                :rules="rules"
                                :items="companies"
                                item-text="name"
                                item-value="company_id"
                            ></v-select>
                            <v-select
                                outlined
                                dense
                                label="For"
                                placeholder="For"
                                v-model="preadvise"
                                :rules="rules"
                                :items="preadvises"
                                item-text="name"
                                item-value="company_id"
                            ></v-select>
                            <v-select
                                outlined
                                dense
                                label="ContainerSize"
                                placeholder="ContainerSize"
                                v-model="containerSizeId"
                                :rules="rules"
                                :items="container_sizes"
                                item-text="size"
                                item-value="container_size_id"
                            ></v-select>
                            <v-select
                                outlined
                                dense
                                label="ContainerType"
                                placeholder="ContainerType"
                                v-model="containerTypeId"
                                :rules="rules"
                                :items="container_types"
                                item-text="type"
                                item-value="container_type_id"
                            ></v-select>
                            <v-select
                                outlined
                                dense
                                label="ContainerClass"
                                placeholder="ContainerClass"
                                v-model="containerClassId"
                                :rules="rules"
                                :items="container_classes"
                                item-text="class"
                                item-value="container_class_id"
                            ></v-select>
                            <!-- <v-select
                                outlined
                                dense
                                label="Destination"
                                placeholder="Destination"
                                v-model="destinaionId"
                                :rules="rules"
                                :items="destinations"
                                item-text="destination_type"
                                item-value="destination_id"
                            ></v-select> -->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red"
                                dark
                                @click="saveContainerInformation"
                            >
                                <v-icon>mdi-content-save</v-icon>
                                <v-icon>mdi-send</v-icon>
                                save & send
                            </v-btn>
                            <v-btn
                                @click="encodeContainerInformationDialog = false"
                            >cancel</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        <!--encodeContainerInformationDialog finish-->
      </v-container>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      totalNumberOfRecords: null,
      dates: [
        `${moment().format('YYYY')}-${moment().format('MM')}-01`,
        `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
      ],
      menu: false,
      headers: [
        {
          text: "TransactionId",
          value: "transaction_id",
          sortable: true,
        },
        {
          text: "Container#",
          value: "container_number",
          sortable: true,
        },
        {
          text: "CompanyName",
          value: "company.name",
          sortable: true,
        },
        {
          text: "For",
          value: "preadvise",
          sortable: true,
        },
        {
          text: "ContainerSize",
          value: "size.size",
          sortable: true,
        },
        {
          text: "ContainerType",
          value: "type.type",
          sortable: true,
        },
        {
          text: "ContainerClass",
          value: "class.class",
          sortable: true,
        },
        {
          text: "Destination",
          value: "destination.destination_type",
          sortable: true,
        },
        {
          text: "Remark",
          value: "remark.remark_name",
          sortable: true,
        },
        {
          text: "status",
          value: "status_history[0].status.status_code",
          sortable: true,
        },
      ],

      items: [],
      encodeContainerInformationDialog: false,
    //   userEmailAddress: null,
      containerNumber: null,
      companyId: this.$store.state.user.company_id,
      preadvise: null,
      preadvises: ['FOR MILT', 'FOR SURVEY ONLY'],
      containerSizeId: null,
      containerTypeId: null,
      containerClassId: null,
    //   destinaionId: null,
      rules: [(v) => !!v || "Required"],
      container_sizes: [],
      container_types: [],
      container_classes: [],
      companies: [],
      bookingScoreboardDialog: false,
      bookingScoreboardData: [],
      bookingScoreboardHeaders: [
        {
          text: "Company",
          value: "COMPANY",
          sortable: true,
        },        
        {
          text: "FOR MILT",
          value: "FOR MILT",
          sortable: true,
        },        
        {
          text: "For Survey & Stacking Only",
          value: "FOR SURVEY ONLY",
          sortable: true,
        },        
        {
          text: "Total",
          value: "TOTAL",
          sortable: true,
        },        
      ]
    }
  },
  mounted() {
    if( this.$store.state.user.role == 'mrstaff' || this.$store.state.user.role == 'user' || this.$store.state.user.role == 'qronly' || this.$store.state.user.role == 'hrassistant' ) {
        alert('You have not access right to enter the area...');
        this.$router.push('/');
    }
    this.loadData();
    if( this.$route.params.container_number ) {
      this.search = this.$route.params.container_number;
    } else {
      this.search = null;
    }
    this.loadCompanies();
  },
  methods: {
    test: function() {
        this.axios.post(
            '/api/test',
            {
                container_number: 'AAAAAAAAAA'
            }
        ).then(response => {
            // console.log(reponse.data);
        }).catch(error => {
            console.log(error);
        });
    },
    loadData: function() {

    //   console.log(this.dates)

      this.overlay = true;
      this.loading = true;
      this.axios.post(
        '/api/get_transactions_for_dpw',
        {
            company_id: this.$store.state.user.company_id,
            dates: this.dates,
            role: this.$store.state.user.role,
            page: this.page,
            search: this.search,
            itemsPerPage: this.itemsPerPage,
        }
      ).then(response => {
        // console.log(response.data)
        this.items = response.data.data;
        this.totalNumberOfRecords = response.data.total;
        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    loadCompanies: function() {
        this.overlay = true;
        this.loading = true;
        this.axios.post(
            '/api/get_companies',
        ).then(response => {
            this.companies = response.data;
            // console.log(response.data);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
    },
    openEncodeContainerInformationDialog: function() {
        this.encodeContainerInformationDialog = true;
        // this.userEmailAddress = null;
        this.containerNumber = null;
        this.containerSizeId = null;
        this.containerTypeId = null;
        this.containerClassId = null;
        // this.destinaionId = null;

        this.axios.post('/api/get_container_sizes')
                .then(response => {
                    this.container_sizes = response.data;
                }).catch(error => {
                    console.log(error);
                });
        this.axios.post('/api/get_container_types')
            .then(response => {
                this.container_types = response.data;
            }).catch(error => {
                console.log(error);
            });
        this.axios.post('/api/get_container_classes')
            .then(response => {
                this.container_classes = response.data;
            }).catch(error => {
                console.log(error);
            });
        // this.axios.post('/api/get_destinations')
        //     .then(response => {
        //         this.destinations = response.data;
        //     }).catch(error => {
        //         console.log(error);
        //     });
    },
    saveContainerInformation: function() {
        if (!this.$refs.form.validate()) {
        return false;
      }
      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/save_transaction',
        {
          created_by: this.$store.state.user.id,
          company_id: this.companyId,
        //   email: this.userEmailAddress,
          container_number: this.containerNumber,
          container_size_id: this.containerSizeId,
          container_type_id: this.containerTypeId,
          container_class_id: this.containerClassId,
          preadvise: this.preadvise
        //   destination_id: this.destinaionId
        }
      ).then(response => {
        // console.log(response.data);
        if(response.status === 200) {
            this.encodeContainerInformationDialog = false;
            const payload = {
                title: "Container Saved!",
                msg: "The information has been successfully saved in our database...",
            };
            this.$store.commit("showAlert", payload);
            this.loadData();
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    upperCase: function() {
        if(!this.containerNumber) {
            return false;
        }
        this.containerNumber = this.containerNumber.toUpperCase();
        if( this.containerNumber.match(/[^A-Z0-9]/g) ) {
            this.containerNumber = this.containerNumber.replace(/[^A-Z0-9]/g, "");
        }
    },
    changePage: function() {
      this.loadData();
    },
    searchByKeyword: function() {
      // console.log(this.search)
      this.loadData();
    },
    downloadBookingScorboardExcel: function() {
      // console.log(this.dates)
      axios.post(
          '/api/download_booking_scoreboard_excel',
          {
            dates: this.dates
          }
      ).then(response => {
          this.downloading = false;
          this.overlay = false;
          const fileLink = document.createElement("a");
          fileLink.href = response.data['file'];
          fileLink.download = 'BookingScoreboard_' + moment().format('YYYYMMDD') + '.xlsx';
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
      }).catch(error => {
          console.log(error);
      });
    },
    openBookingScoreboardDialog: function() {
      this.bookingScoreboardDialog = true;
      this.getBookingScoreboardData();
    },
    getBookingScoreboardData: function() {
      axios.post(
          '/api/get_booking_scoreboard_data',
          {
            dates: this.dates
          }
      ).then(response => {
          this.downloading = false;
          this.overlay = false;
          this.bookingScoreboardData = response.data;
          // console.log(response.data);
      }).catch(error => {
          console.log(error);
      });      
    }
  },
  watch: {
    dates: function() {
        if (this.dates.length == 0 && this.dates.length == 1) {
            return false;
        } else if (this.dates.length == 2) {
            if ( this.dates[0] > this.dates[1] ) {
                alert('Invalid date selection');
                this.dates = [];
                return false;
            } else {
                this.page = 1;
                this.loadData();
            }
        }
    }
  },
  filters: {
    showStatuses: function(value) {
      let statuses = [];
      for( var s of value ) {
        statuses.push(s.status_id);
      }
      return statuses.join(' | ');
    }
  },
  computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },
}
</script>