<template>
    <v-container>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Job Orders</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                                <v-tab
                                    v-for="(destination, i) in destinations"
                                    :key="i"
                                >
                                    {{destination.destination_type}}({{destination.destination_id}})
                                </v-tab>
                        </v-tabs>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-magnify"
                            label="Search Container#..."
                            placeholder="Search Container#..."
                            v-model="search"
                            class="mt-2"
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                        <!-- <v-select
                            outlined
                            dense
                            label="Status"
                            :items="statuses"
                            v-model="status"
                        ></v-select> -->
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                                    v-model="dateRangeText"
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>
                        <!--Data Picker finish-->
                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                    </template>
                    <template v-slot:[`item.gate_in_at`]="{ item }">
                        <div v-if="!item.gate_in_at">
                            <v-btn
                                color="orange"
                                dark
                                small
                                @click="gateIn(item)"
                                :disabled="tab == 3"
                            >
                            <v-icon>mdi-boom-gate-arrow-up</v-icon> Gate In
                        </v-btn>
                        </div>
                        <div v-else>
                            {{item.gate_in_at}}
                        </div>
                    </template>
                    <!-- <template v-slot:[`item.transaction.container_located_at`]="{ item }">
                        <div v-if="item.transaction.container_located_at">
                            {{ item.transaction.container_located_at }}
                        </div>
                        <div v-else>
                            <v-btn
                                color="orange"
                                dark
                                small
                                v-if="item.gate_in_at"
                                @click="putTimestampInContainerLocatedAt(item)"
                            >
                                <v-icon>mdi-timetable</v-icon>
                                put timestamp
                            </v-btn>
                        </div>
                    </template>                     -->
                    <template v-slot:[`item.back_to_survey`]="{ item }">
                        <v-btn
                                color="orange"
                                dark
                                small
                                :disabled="item.gate_out_at != null || $store.state.role != 'su'"
                                @click="backToSurvey(item)"
                            >
                            <v-icon>mdi-magnify-expand</v-icon> Back to Survey
                        </v-btn>
                    </template>
                    <template v-slot:[`item.job_started_at`]="{ item }">
                        <div v-if="!item.job_started_at">
                            <v-btn
                                color="orange"
                                dark
                                small
                                :to="`/damage_list/${item.transaction_id}/${item.container_number}/${item.survey_id}/${item.job_order_id}/${tab}`"
                                :disabled="!item.gate_in_at || !item.transaction.container_located_at"
                            >
                                <v-icon>mdi-play-circle</v-icon> Start Job
                            </v-btn>
                        </div>
                        <div v-else>
                            <div v-if="item.job_closed_at">
                                {{ item.job_started_at }}
                            </div>
                            <div v-else>
                                <a :href="`/damage_list/${item.transaction_id}/${item.container_number}/${item.survey_id}/${item.job_order_id}/${tab}`">{{ item.job_started_at }}</a>
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.job_closed_at`]="{ item }">
                        <div v-if="!item.job_closed_at">
                        -
                        </div>
                        <div v-else>
                            {{item.job_closed_at}}
                        </div>
                    </template>
                    <template v-slot:[`item.gate_out_at`]="{ item }">
                        <div v-if="!item.gate_out_at && item.transaction.container_location2">
                            <v-btn
                                color="orange"
                                dark
                                small
                                @click="openLocationSelectionFormForDpwDialog(item)"
                                :disabled="!item.job_closed_at"
                            >
                            <v-icon>mdi-boom-gate-arrow-down</v-icon> Gate Out
                        </v-btn>
                        </div>
                        <div v-else>
                            {{item.gate_out_at}}
                        </div>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                            total-visible="10"
                            dark
                            @input="changePage()"
                        ></v-pagination>
                    </div>
            </v-card-text>
        </v-card>

        <!-- locationSelectionForDpwDialog start -->
        <v-dialog v-model="showLocationSelectionFormForDpwDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            Select Location -- Container#: {{ currentContainerNumber }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="showLocationSelectionFormForDpwDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-select
                            outlined
                            dense
                            label="Container Location2"
                            placeholder="Container Location2"
                            v-model="containerLocation3"
                            :items="containerLocations3"
                        ></v-select>
                    </v-card-text>     
                    <v-card-actions>
                        <v-btn
                            v-if="showSelectLocation3Button"
                            color="green"
                            dark
                            block
                            @click="goToNext()"
                        >go to next</v-btn>
                    </v-card-actions>    
                </v-container>
            </v-card>
        </v-dialog>
        <!-- locationSelectionFormForDpwDialog end -->
        <!-- encodingFormForDpwDialog start -->
        <v-dialog v-model="showEncodingFormForDpwDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            Encode Details -- Container#: {{ currentContainerNumber }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="showEncodingFormForDpwDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    height="100"
                                    outlined
                                    dense
                                    label="Container Position"
                                    placeholder="Container Position"
                                    v-model="containerPosition"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    height="100"
                                    outlined
                                    dense
                                    label="Truck Details"
                                    placeholder="Truck Details"
                                    v-model="truckDetails"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    height="100"
                                    outlined
                                    dense
                                    label="Side Lifter Details"
                                    placeholder="Side Lifter Details"
                                    v-model="sideLifterDetails"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            v-if="showSaveDetailsBeforeGateOutButton"
                            color="green"
                            dark
                            block
                            @click="saveDetailsBeforeGateOut()"
                        >
                        <v-icon>mdi-content-save-outline</v-icon>
                        save details before gate out</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>         
        <!-- encodingFormForDpwDialog end -->
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            menu: false,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ],
            headers: [
                {
                    text: "JobOrderId",
                    value: "job_order_id",
                    sortable: false,
                },
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "ContainerNumber",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "SurveyId",
                    value: "survey_id",
                    sortable: false,
                },
                {
                    text: "Destination",
                    value: "transaction.destination_id",
                    sortable: false,
                },
                {
                    text: "BackToSurvey",
                    value: "back_to_survey",
                    sortable: false,
                },
                {
                    text: "GateInAt",
                    value: "gate_in_at",
                    sortable: false,
                },
                {
                    text: "ContainerLocation",
                    value: "transaction.container_location.container_location",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt",
                    value: "transaction.container_located_at",
                    sortable: false,
                },                
                {
                    text: "JobStartedAt",
                    value: "job_started_at",
                    sortable: false,
                },
                {
                    text: "JobClosedAt",
                    value: "job_closed_at",
                    sortable: false,
                },
                {
                    text: "ContainerLocation2",
                    value: "transaction.container_location2",
                    sortable: false,
                },                
                {
                    text: "ContainerLocatedAt2",
                    value: "transaction.container_located_at2",
                    sortable: false,
                },                
                {
                    text: "ContainerLocation3",
                    value: "transaction.container_location3",
                    sortable: false,
                },                
                {
                    text: "ContainerLocatedAt3",
                    value: "transaction.container_located_at3",
                    sortable: false,
                },                
                {
                    text: "GateOutAt",
                    value: "gate_out_at",
                    sortable: false,
                },
            ],
            items: [],
            containerNumber: null,
            transactionId: null,
            surveyId: null,
            search: null,
            jobOrderServiceDialog: false,
            tab: 0,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            part_names: [],
            partName: [],
            descriptions: [],
            description: null,
            locations: [],
            location: null,
            way_of_repairs: [],
            wayOfRepair: null,
            serviceId: null,
            openEncodeJobOrderServicesDialog: false,
            currentContainerNumber: null,
            currentJobOrder: null,
            currentJobOrderServiceId: null,
            currentJobOrderId: null,
            currentDamage: null,
            jobOrderServices: [],
            destinations: [],
            postServicePicturesUploadingDialog: false,
            userFiles: [],
            progressLinear: null,
            formData: null,
            postServicePictureHeaders: [
                {
                    text: 'TransactionId',
                    value: 'transaction_id',
                    sortable: true,
                },
                {
                    text: 'ContainerNumber',
                    value: 'container_number',
                    sortable: true,
                },
                {
                    text: 'FileName',
                    value: 'file_name',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                }
            ],
            postServicePictures: [],
            // status: 'All',
            // statuses: [
            //     'All',
            //     'Surveyed',
            //     'Job Closed',
            //     'Gate Out'
            // ],
            showLocationSelectionFormForDpwDialog: false,
            currentItem: null,
            showEncodingFormForDpwDialog: false,
            containerLocations3: ['ATI-HRD POOL', 'HRD TRUCK PICK UP', 'STACKYARD'],
            containerLocation3: null,
            containerPosition: null,
            truckDetails: null,
            sideLifterDetails: null,
        }
    },
    mounted() {

        if( this.$store.state.user.role == 'surveyor' || this.$store.state.user.role == 'user'|| this.$store.state.user.role == 'qronly' || this.$store.state.user.role == 'hrassistant' ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }

        if( this.$route.params.job_order_id && this.$route.params.tab ) {
            this.search = this.$route.params.job_order_id;
            this.tab = parseInt(this.$route.params.tab);
        } else if( !this.$route.params.job_order_id && this.$route.params.tab ) {
            this.tab = parseInt(this.$route.params.tab);
        } else {
            this.search = null;
            this.tab = 0;
        }
        this.getDestinations();
        this.getJobOrders(this.tab);
    },
    methods: {
        statusColor: function(status) {
            switch(status) {
                case 'Not Yet Fixed':
                return 'red';
                break;
                case 'Ongoing':
                return 'blue';
                break;
                case 'Fixed':
                return 'green';
                break;
            }
        },
        getJobOrders: function(tab) {
            this.overlay = true;
            this.loading = true;
            // console.log(this.dates)
            this.axios.post(
                '/api/get_job_orders',
                {
                    tab: tab,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    // status: this.status,
                    dates: this.dates,
                    search: this.search,
                }
            ).then(response => {
                this.items = response.data.data;
                this.totalNumberOfRecords = response.data.total;
                this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getDamages: function(item) {
            this.axios.post(
                '/api/get_damages',
                {
                    transaction_id: item.transaction_id,
                    survey_id: item.survey_id,
                }
            ).then(response => {
                // console.log(response.data);
                this.damages = response.data;

                const unFixedDamages = this.damages.filter(function(value) {
                    return value.status_code != 'Fixed';
                });

                // console.log(this.damages)

                const postServicePictures = this.damages.filter(function(value) {
                    return value.picture.post_service_pictures.length > 0;
                });

                // console.log(postServicePictures);

                if( unFixedDamages.length == 0 && (postServicePictures.length >= this.damages.length ) ) {
                    this.canCloseJob = true;
                } else {
                    this.canCloseJob = false;
                }

            }).catch(error => {
                console.log(error);
            });
        },
        gateIn: function(item) {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/gate_in',
                {
                    job_order_id: item.job_order_id,
                    user_id: this.$store.state.user.id,
                    transaction_id: item.transaction_id
                }
            ).then(response => {
                this.getJobOrders(this.tab);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = true;
                this.loading = true;
            });
        },
        openLocationSelectionFormForDpwDialog: function(item) {
            this.containerLocation3 = null;
            this.showLocationSelectionFormForDpwDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = this.currentItem.container_number;
        },
        gateOut: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/gate_out',
                {
                    transaction_id: this.currentItem.transaction_id,
                    job_order_id: this.currentItem.job_order_id,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                this.getJobOrders(this.tab);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = true;
                this.loading = true;
            });
        },
        startJob: function(item) {
            // this.jobOrderServiceDialog = true;
            // this.overlay = true;
            // this.loading = true;
            // this.currentJobOrder = item;
            // this.containerNumber = item.container_number;
            // this.transactionId = item.transaction_id;

            // this.axios.post('/api/get_part_names')
            //     .then(response => {
            //         // console.log(response.data)
            //         if(response.status === 200) {
            //             this.part_names = response.data;
            //         }
            //     }).catch(error => {
            //         console.log(error);
            //     }).finally(() => {
            //         this.overlay = false;
            //         this.loading = false;
            //     });
            // this.getDamages(item);

            // this.overlay = true;
            // this.loading = true;

            // this.axios.post(
            //     '/api/start_job',
            //     {
            //         job_order_id: item.job_order_id
            //     }
            // ).then(response => {
            //     this.getJobOrders();
            // }).catch(error => {
            //     console.log(error);
            // }).finally(() => {
            //     this.overlay = true;
            //     this.loading = true;
            // });
        },
        uploadPictures: function(item) {
            // console.log(item)
            // ここに画像アップロードフォームを開くコードを含む。
            this.postServicePicturesUploadingDialog = true;
            this.containerNumber = item.container_number;
            this.transactionId = item.transaction_id;
            this.currentJobOrderId = item.job_order_id;
        },
        addFiles: function() {
            this.formData = new FormData();

            if( typeof this.userFiles === 'undefined' || this.userFiles.length === 0 ) {
                return false;
            }

            for(var file of this.userFiles) {
                if( typeof file === 'undefined' ) {
                    return false;
                }
                if (!file.name.match(/png|jpg$/i)) {
                    const payload = {
                        title: "Invalid file chosen..",
                        msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                    };
                    this.$store.commit("showAlert", payload);
                    return false;
                } else {
                    this.formData.append("file", file);
                }
            }

            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('picture_id', this.currentDamage.picture_id);
            this.formData.append('filename', this.currentDamage.picture.file_name);
            this.formData.append('job_order_service_id', this.currentJobOrderServiceId);
            this.formData.append('seq_no', this.postServicePictures.length + 1);

            this.overlay = true;
            this.loading = true;

            this.axios
                .post(
                "/api/save_post_service_file",
                this.formData,
                // {
                //     onUploadProgress: this.onUpload,
                // },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {
                    // this.areas.splice(this.areas.indexOf(a), 1);
                    // this.userFiles.splice(i, 1);
                    // this.progressLinears.splice(i, 1);
                    this.userFiles = [];
                    this.getPostServicePictures(this.currentJobOrderServiceId);
                    this.getJobOrderServicesByDamageId();
                    this.getDamages(this.currentJobOrder);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        // onUpload: function (e) {
        //     this.$set(
        //         this.progressLinear,
        //         0,
        //         Math.floor((e.loaded / e.total) * 100)
        //     );
        // },
        getJobOrderServicesByDamageId: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_job_order_services_by_damage_id',
                {
                    job_order_id: this.currentJobOrder.job_order_id,
                    damage_id: this.currentDamage.damage_id,
                }
            ).then(response => {
                console.log(response.data);
                this.jobOrderServices = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getPostServicePictures: function(job_order_service_id) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_post_service_pictures',
                {
                    job_order_service_id: job_order_service_id,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.postServicePictures = response.data;

                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deletePostServicePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_post_service_picture',
                {
                    id: item.id,
                    container_number: item.container_number,
                    transaction_id: item.transaction_id,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Picture deleted",
                        msg: "The picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.getPostServicePictures(this.currentJobOrderServiceId);
                    this.getJobOrderServicesByDamageId();
                    this.getDamages(this.currentJobOrder);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        viewPostServicePicture: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_post_service_picture",
                    {
                        transaction_id: this.transactionId,
                        container_number: this.containerNumber,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },
        backToSurvey: function(item) {
            // console.log(item)
            // return false;
            // this.$router.push('/surveys');
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/back_to_survey",
                    {
                        survey_id: item.survey_id,
                    }
                )
                .then(response => {
                    if(response.status === 200) {
                        // console.log(response.data);
                        // return false;
                        this.$router.push('/survey/2/' + item.transaction_id);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },
        closeJob: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/close_job',
                {
                    job_order_id: this.currentJobOrder.job_order_id,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                this.getJobOrders(this.tab);
                this.jobOrderServiceDialog = false;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = true;
                this.loading = true;
            });
        },
        changePage: function() {
            this.getJobOrders(this.tab);
        },
        getDestinations: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post('/api/get_destinations')
                .then(response => {
                    this.destinations = response.data;
                    // console.log(this.destinations);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        searchByKeyword: function() {
            this.getJobOrders(this.tab);
        },
        goToNext: function() {
            if( this.containerLocation3 === 'STACKYARD' ) { 
                this.saveDetailsOnly();
            } else {
                this.containerPosition = null;
                this.truckDetails = null;
                this.sideLifterDetails = null;
                this.showEncodingFormForDpwDialog = true;
                this.showLocationSelectionFormForDpwDialog = false;
            }
        },
        saveDetailsOnly: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/save_details_only',
                {
                    transaction_id: this.currentItem.transaction_id,
                    container_location3: this.containerLocation3
                }
            )
                .then(response => {
                    // console.log(response.data)
                    this.showLocationSelectionFormForDpwDialog = false;
                    this.getJobOrders(this.tab);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });       
        },
        saveDetailsBeforeGateOut: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/save_details_before_gate_out',
                {
                    transaction_id: this.currentItem.transaction_id,
                    container_location3: this.containerLocation3,
                    container_position: this.containerPosition,
                    truck_details: this.truckDetails,
                    side_lifter_details: this.sideLifterDetails
                }            
            )
                .then(response => {
                    this.gateOut();
                    this.showEncodingFormForDpwDialog = false;
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },
        putTimestampInContainerLocatedAt: function(item) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/put_timestamp_in_container_located_at',
                {
                    transaction_id: item.transaction_id
                }            
            )
                .then(response => {
                    if(response.status == 200) {
                        this.getJobOrders(this.tab);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });    
        },
    },

    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
    watch: {
        tab: function() {
            this.getJobOrders(this.tab);
        },
        // status: function() {
        //     this.getJobOrders(this.tab);
        // },
        dates: function() {
            if (this.dates.length == 0 && this.dates.length == 1) {
                return false;
            } else if (this.dates.length == 2) {
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                } else {
                    this.page = 1;
                    this.getJobOrders(this.tab);
                }
            }
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        },
        showSaveDetailsBeforeGateOutButton: function() {
            if( this.containerPosition && this.truckDetails && this.sideLifterDetails) {
                return true;    
            } else {
                return false;
            }
        },
        showSelectLocation3Button: function() {
            if( this.containerLocation3 ) {
                return true;    
            } else {
                return false;
            }
        },
    },
}
</script>
